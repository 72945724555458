/**
 * This file contains fixes for ngx-datatable component issues
 */

/* Fix for table width not filling container on page refresh */
ngx-datatable {
  width: 100% !important;
  
  .datatable-header {
    width: 100% !important;
  }
  
  .datatable-body {
    width: 100% !important;
    
    .datatable-scroll {
      width: 100% !important;
    }
    
    .datatable-row-wrapper {
      width: 100% !important;
      
      .datatable-body-row {
        width: 100% !important;
      }
    }
  }
  
  datatable-scroller {
    width: 100% !important;
  }
}