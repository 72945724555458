/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// Import datatable fixes
@import 'datatable-fix';

.small-paginator {
    .mat-mdc-paginator-container {
        padding: 0 !important;
        @apply w-full #{'!important'};
        @apply flex #{'!important'};
        @apply justify-between #{'!important'};
    }

    .mat-mdc-paginator-page-size-label {
        @apply text-xs #{'!important'};
    }

    .mat-mdc-paginator-range-label {
        @apply text-xs #{'!important'};
        @apply my-0 #{'!important'};
        @apply mx-2 #{'!important'};
    }

    .mat-mdc-paginator-range-actions {
        @apply mr-2 #{'!important'};
    }

    .mat-mdc-icon-button {
        width: 24px !important;
    }
}

//Leaflet styles
.leaflet-div-icon {
    width: 0px !important;
    height: 0px !important;
}

.leaflet-bottom {
    z-index: 999 !important;
}

//MDC: fix search-by-input (filters)
.search-by-input {
    .mat-mdc-form-field-flex {
        @apply px-0 #{'!important'};
    }
    .mat-mdc-form-field-icon-suffix {
        @apply bg-gray-200 #{'!important'};
        width: 46px;
        min-height: 48px;

        button {
            @apply pr-3;
            margin-right: 0 !important;
        }
    }
}

//MDC: fix configuration-input
.configuration-input {
    .mat-mdc-form-field-flex {
        @apply px-0 #{'!important'};
    }
    .mat-mdc-form-field-icon-suffix {
        border-left: 1px solid rgb(203 213 225 / var(--tw-border-opacity)) !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 56px;
        padding: 0 20px;

        button {
            @apply pr-3;
            margin-right: 0 !important;
        }
    }
}

//MDC: field-with-gray-suffix
.field-with-gray-suffix {
    .mat-mdc-form-field-flex {
        @apply px-0 #{'!important'};
    }
    .mat-mdc-form-field-icon-suffix {
        min-width: 50px !important;
        background-color: #CBD5E1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: 1px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 0 8px 0 12px;

        button {
            @apply pr-3;
            margin-right: 0 !important;
        }
    }
    .mat-mdc-select-value-text {
        font-weight: bold;
        color: #64748B;
        font-size: 14px;
    }
}
.mat-form-field-invalid.field-with-gray-suffix {
    .mat-mdc-form-field-icon-suffix {
        background-color: #eeeeee;
        .mat-mdc-select-value-text {
            color: var(--mdc-theme-error, #dc2626);
        }
    }
}

// MDC: fix mat form field
.mat-mdc-form-field-infix {
    width: auto !important;
}
.mat-mdc-form-field-icon-prefix {
    @apply ml-3 #{'!important'};
}

// MDC: fix dropdown filters
.mat-mdc-menu-panel .mat-mdc-checkbox, .mat-mdc-checkbox, .mdc-checkbox {

}
.mat-mdc-checkbox .mdc-label {
    line-height: normal;
}

//MDC: fix mat-chip
.mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label,
.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary{
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.mat-mdc-chip-action-label {
    @apply flex #{'!important'};
    @apply items-center #{'!important'};
}

//MDC: fix tags
.tag-actions > * {
    padding-left: 2px !important;
    padding-right: 0 !important;;
}

app-tags .mat-mdc-form-field-flex {
    padding: 0 0 0 10px !important;
}

app-tags .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
    box-shadow: none !important;
    border-width: 0 !important;
    background-color: transparent !important;
}
app-tags .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    min-height: 25px;
}

// MDC: fix tabs
.mat-mdc-tab-group .mat-mdc-tab-body-content {
    padding: 0;
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container {
    margin: 0;
}

// MDC: fix dialog
.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0;
}

// MDC: fix accordion and expansion-panel
.mat-accordion .mat-expansion-panel, .mat-accordion .mat-expansion-panel:hover {
    box-shadow: none !important;
    border-radius: 0 !important;
}
.extension-panel-no-paddings .mat-expansion-panel-body {
    padding: 0 !important;
}
.mat-accordion .mat-expansion-panel.mat-expanded, .mat-accordion .mat-expansion-panel:hover {
    box-shadow: none !important;
}

// MDC: change checkbox/radio smaller (as was)
html {
    --mdc-checkbox-state-layer-size: 20px;
}

.mat-mdc-radio-button .mdc-radio {
    --mdc-radio-state-layer-size: 20px;
}


.no-ripple .mdc-switch__ripple { display: none; }

.mat-mdc-icon-button.no-ripple .mat-mdc-button-persistent-ripple::before {
    content: "";
    opacity: 0;
    background-color: inherit !important;
}
// MDC: fix calendar with month view
.mat-calendar-table { width: 100%; }

// remove background in mat-stepper
.mat-step-header {
    &.cdk-keyboard-focused,
    &.cdk-program-focused,
    &:hover:not([aria-disabled]),
    &:hover[aria-disabled='false'] {
        background-color: transparent !important;
    }
}

.fuse-horizontal-navigation-item {
    width: 100%;
}

.mat-mdc-option.mdc-list-item--disabled {
    pointer-events: inherit !important;
}

gridster {
    background-color: transparent !important;

    .gridster-row {
        border-top: 1px dashed theme('colors.gray[300]') !important;
        border-bottom: 1px dashed theme('colors.gray[300]') !important;
    }

    .gridster-column {
        border-left: 1px dashed theme('colors.gray[300]') !important;
        border-right: 1px dashed theme('colors.gray[300]') !important;
    }

    gridster-item {
        @apply rounded-2xl #{'!important'};
        @apply overflow-hidden #{'!important'};
    }
}


.header-less-tabs.mat-mdc-tab-group .mat-mdc-tab-header {
    display: none;
}

.select-search {

    @apply border;
    @apply border-solid;
    @apply border-gray-400;
    @apply rounded-md;
    @apply mx-2;

    .mat-pseudo-checkbox {
        display: none;
    }

    .mat-mdc-pseudo-checkbox {
        display: none;
    }
}

.select-search * {
    opacity: 1 !important;
}

.z-997 {
    z-index: 997;
}
.z-998 {
    z-index: 998;
}

.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
    background-color:  #F1F5F9 !important;
}

.mobile-datatable {
    .page-count { display: none; }
}

.mobile-navigation {
    fuse-vertical-navigation fuse-vertical-navigation-basic-item > .fuse-vertical-navigation-item-wrapper {
        margin: 0 !important;
    }
    .fuse-vertical-navigation-item-badge {
        padding-left: 12px !important;
    }
}

mat-option {
  .select-search::placeholder {
    opacity: 1 !important;
  }
}

.auto-width {
    max-width: none !important;
}

.filter-inputs {
    .mat-button-toggle-vertical {
        width: 100%;
        padding-right: 4px;

        .mat-button-toggle {
            margin-bottom: 4px;
        }

        .mat-button-toggle .mat-button-toggle-label-content {
            padding: 0 20px;
            line-height: 30px !important;
        }
    }
}

.align-top {
    vertical-align: top;
}

.ql-custom-height {
    .ql-container {
        min-height: 56px !important;
        max-height: none !important;
        border-radius: 6px !important;
        margin: 0 5px 0 5px;
        padding-top: 6px !important;
        //border: none !important;
        //box-shadow: none !important;
    }
    .ql-toolbar+.ql-container {
        margin: 0 !important;
        border-radius: 0 0 6px 6px !important;
    }

}

.mention {
    margin-right: 0 !important;
}

.html-preview  {
    p {
        margin-bottom: 10px;
    }
}

.my-tooltip {
    white-space: preserve;
}

.show-popup-on-hover {
    .popup {
        @apply hidden absolute  z-9999 rounded-md border border-slate-500 p-1 w-120 max-h-140 overflow-y-auto bg-white;
    }

    &:hover {
        .popup {
            display: block !important;
        }
    }
}

.assistant-icon {
    color: #9ACC59;
}
